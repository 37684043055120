import Bugsnag from '@bugsnag/js'

document.addEventListener("DOMContentLoaded", function (_event) {
    let bugsnagInfo = document.getElementById('bugsnag_info')
    if (!!bugsnagInfo) {
        Bugsnag.start({
            apiKey: '2df5846c3a69f02bf5c0ee6269389c96',
            appVersion: bugsnagInfo.dataset.appVersion,
            collectUserIp: false,
            // "hostname" does only work in node Version of the lib. But there is an open Feature Request for bugsnag
            // to also enable this in browser mode for a future release.
            hostname: bugsnagInfo.dataset.hostName,
            metadata: {
                account: {
                    id: bugsnagInfo.dataset.accountId,
                    name: bugsnagInfo.dataset.accountName
                }
            },
            user: {
                id: bugsnagInfo.dataset.userId
            }
        })
    }
})
